import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>TOOTH EROSION</h2>
      <h3>What is it?</h3>
      <p>Acids in the mouth can dissolve  away tooth surfaces.  Given the chance, teeth will repair themselves, using minerals  from saliva. But if acid is in the mouth too often, teeth cannot repair themselves and the hard tooth surface (the enamel)  becomes thinner- this is called "erosion".</p>
      <p>The teeth can then become extra sensitive to hot and cold food and drink. Eroded teeth can also be more likely to suffer decay.</p>
      <p>The main cause of erosion is too frequent consumption of certain kinds of food and drink. All fizzy drinks (including "diet" brands and fizzy mineral water), all "sports" drinks, all squashes and all fruit juices are acidic to varying degrees.  Pickles and citrus fruits are examples of acidic types of food.</p>
      <p>Some medicines are acidic and, therefore, erosive.</p>
      <p>And people  with some illnesses (such as eating disorders) may suffer from erosion because  of frequent  vomiting,  as stomach  acids also erode teeth. For this reason, dentists may ask about eating disorders if they see teeth that are very badly eroded.</p>
      <h3>How do I prevent erosion?</h3>
      <p>Don't have acidic food and I or drink too often during the day. Try to have them only at meal-times. And drink acidic drinks quickly - don't sip them. And don't swish them round your mouth.</p>
      <p>Between meals you should only have "safe" drinks, which are not sugary or acidic. Milk and water are "safe" drinks. So are tea and coffee if you do not add sugar to them (you can use non-sugar sweeteners).</p>
      <p>You should try and avoid snacking between meals. If you do snack, only have "safe" snacks, which are not sugary or acidic. Fruits, vegetables and products (such as sandwiches, toast, crumpets and pitta bread) are all "safe" snacks. You should try and avoid snacking between meals. Some fruits, especially citrus fruits, are acidic and are known to cause erosion if they are consumed in large quantities. This is not normally a problem for most people; however, you could discuss with your dentist or hygienist the safest way of enjoying these fruits.</p>
      <p>Because acids temporarily soften the tooth surface, don't brush your teeth immediately after eating or drinking something acidic.</p>
      <p>You should brush your teeth twice a day, and always use a fluoride toothpaste.</p>
      <h3>How can my dentist help?</h3>
      <p>Your dentist can identify erosion, pinpoint the causes and advise you how to prevent further damage.</p>
    </LightboxLayout>
  )
}

export default Page